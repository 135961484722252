// @ts-nocheck
import { getOrderID } from "../API/OrderAPIs";
import { USER } from "../constants/localStorageKeys";
import { orderSuccess } from "../API/OrderAPIs";
import "../App.css";
import swal from "sweetalert2";
import WalletCard from "../Components/WalletCard";

export const displayRazorPay = async (amount: number) => {
  const data = await getOrderID(amount);
  const mobileNumber = localStorage.getItem(USER.MOBILE_NUMBER);

  let orderID = (await data?.body?.order?.id) || "";
  console.log("order id ***", orderID);
  if (!orderID) {
    alert("Please try again.");
    return;
  }
  const res = await loadRazorpay(
    "https://checkout.razorpay.com/v1/checkout.js",
  );
  if (!res) {
    console.error("No response from checkout script of Razorpay");
    return;
  }
  let options = {
    // key: "rzp_live_L97Jm9bEOfxsAv", // prod
    // key: "rzp_test_PDPyTg6rlmQp0W", /for testing
    key:"rzp_live_pCXg5C32DsLttT",
    amount: "",
    currency: "INR",
    name: "eatoes",
    description: "Make your payment",
    image:
      "https://cdn.dribbble.com/users/24078/screenshots/15473819/media/7eb9bc4f76cb8551771b95b48f732ccf.jpg?compress=1&resize=400x300&vertical=top",
    order_id: orderID, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    // order_id:'',
    prefill: {
      // name: "dummy Name",
      // email: "dummy.name@example.com",
      name: " ",
      email: " ",
      contact: mobileNumber,
    },

    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
      backdrop_color: "#6A75CA",
    },

    handler: function (response) {
      try {
        console.log("razorpay response", response);

        // show the popup for success here
        const data = orderSuccess(
          orderID,
          response.razorpay_payment_id,
          response.razorpay_signature,
        );

        console.log(data);
        swal
          .fire({
            title: "SUCCESS",
            text: "Your Payment Was Successful",
            icon: "success",
            //  confirmButton:'OK',
            confirmButtonColor: "#6cc070",
          })
          .then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            } else {
              location.reload();
            }
          });
      } catch (err) {
        console.log("payment is not successful");
      }
    },
  };
  var paymentObject = new window.Razorpay(options);
  paymentObject.open();
};

const loadRazorpay = (src: string) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
};
// put it in option obj to handle response if 'callback_url' is ot used
// handler: function (response:object) {
//   try {
//     console.log("razorpay response", response);
//     alert(response?.razorpay_payment_id);
//     alert("payment successful");
//   } catch (err) {
//     console.log("payment is not successful");
//   }
// },
